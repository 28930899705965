export default function() {
  return [
    {
      title: "Statistics",
      to: "/",
      htmlBefore: '<i class="material-icons">bar_chart</i>',
      htmlAfter: ""
    }
    ,{
      title: "Videos",
      to: "/video",
      htmlBefore: '<i class="material-icons">play_arrow</i>',
      htmlAfter: ""
    }
    ,{
      title: "News",
      to: "/news",
      htmlBefore: '<i class="material-icons">menu_book</i>',
      htmlAfter: ""
    }
  ];
}
