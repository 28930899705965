import React, { useState } from "react";

import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

class Offers extends React.Component {
  constructor(props) {
    super(props);
    //
    this.state = {
        offers: [],
    };
  }

  fetchOffers(){
      fetch("http://3.11.199.201/api.php?m=get_offers",{
          crossDomain:true
      })
     .then(response => response.json())
     .then(data => {
         this.setState({
             offers : data
         });
     });

  }

  showOffer(index=0){
    let offers = this.state.offers;
    let offer = [];
    if(offers.length <= index){
      return offer;
    }
    offer.push(
      <Col md="6">
        <center>
          <a target="_blank" href={offers[index]["link"]}>
            <img src={offers[index]["picture"]}/> {offers[index]["name_short"]}
          </a>
        </center>
      </Col>
    );
    return offer;
  }

  render() {
    return (
        <Row>
          {/*this.showOffer(0)*/}
          {/*this.showOffer(1)*/}
        </Row>
    );
  }

  componentDidMount(){
      //this.fetchOffers();
  }
}

export default Offers;
