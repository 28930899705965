import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col, Row, Card, CardBody, CardTitle, CardSubtitle } from "shards-react";
import PageTitle from "../common/PageTitle";

// import news from "../../data/news.json";

class LatestNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        news : []
    };
  }

  fetchNews(){
      fetch("http://3.11.199.201/api.php?m=news",{
          crossDomain:true
      })
         .then(response => response.json())
         .then(data => {
             this.setState({
                 news : data
             });
         });
      // this.setState({
      //    news : news
      // });
  }

  componentWillMount() {
      this.fetchNews();
  }

  loadNews(){
      let news_html = [];
      let news = this.state.news;
      for(let i in news){
          news_html.push(
              <Card>
                <CardBody>
                  <CardTitle>
                      <a href={"javascript:{window.location.href='/#/news/"+news[i].id+"';window.scrollTo(0, 0);location.reload();}"}>
                          {news[i].headline}
                      </a>
                  </CardTitle>
                  <br/>
                  <CardSubtitle>{news[i].source} ... {news[i].created_at}</CardSubtitle>
                </CardBody>
              </Card>
          );
          news_html.push(<hr/>);
      }
      return news_html;
  }

  render() {
    return (
      <Col
        md={{ size: 2 }}
      >
          <Row noGutters className="page-header py-3">
            <Col md="12">
                <h4 className="text-center">Latest News</h4>
            </Col>
          </Row>
          <Row>
            <Col md="12">
                {this.loadNews()}
            </Col>
          </Row>
      </Col>
    );
  }
}

export default LatestNews;
