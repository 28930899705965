import React, { useState } from "react";
import ReactTooltip from "react-tooltip";

import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
import MapChart from "./../components/common/MapChart";
import UsersOverview from "./../components/blog/UsersOverview";
import UsersByDevice from "./../components/blog/UsersByDevice";
import NewDraft from "./../components/blog/NewDraft";
import Discussions from "./../components/blog/Discussions";
import TopReferrals from "./../components/common/TopReferrals";

import Offers from "./Offers.js";

import { initializeFirebase, askForPermissioToReceiveNotifications } from '../push-notification';

// import smallStats from "../data/stats.json";
// import countries_stats from "../data/countries_stats.json";

class BlogOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // First list of posts.
      smallStats: [],
      countries_stats: [],
      setContent: (content)=>this.setContent(content),
      content: ()=>this.content(),
    };
  }

  setContent(content){
      this.setState({
          content: content
      })
  }

  content(){
      return this.state.content;
  }

  countries_stats(){
      let rows = [];
      let countries_stats = this.state.countries_stats;
      for(let i in countries_stats){
          let countries_stat = countries_stats[i];
          let row = [];
          row.push(<td><img alt={countries_stat.countryCode} src={"/flags/"+countries_stat.countryCode+".png"}/> {countries_stat.countryName}</td>)
          row.push(<td>{this.numberWithCommas(countries_stat.confirmed)}</td>)
          row.push(<td>{this.numberWithCommas(countries_stat.recovered)}</td>)
          row.push(<td>{this.numberWithCommas(countries_stat.deaths)}</td>)
          rows.push(<tr>{row}</tr>)
      }
      return rows;
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    const {
      smallStats, content, setContent
    } = this.state;

    return (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
              <PageTitle title="Corona Virus Tracker" subtitle="Statistics" className="text-sm-left mb-3" />
          </Row>
          <Offers/>
          {/* Small Stats Blocks */}
          <Row style={{"marginTop":"5px"}}>
            {smallStats.map((stats, idx) => (
              <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                <SmallStats
                  id={`small-stats-${idx}`}
                  variation="1"
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  value={this.numberWithCommas(stats.value)}
                  percentage={stats.percentage}
                  increase={stats.increase}
                  decrease={stats.decrease}
                />
              </Col>
            ))}
          </Row>

          <Row>
            <Col md="12">
                <div style={{backgroundColor: 'white'}}>
                    <MapChart setTooltipContent={setContent}/>
                    <ReactTooltip>{content}</ReactTooltip>
                </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Statistics By Country</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Country
                        </th>
                        <th scope="col" className="border-0">
                          Confirmed
                        </th>
                        <th scope="col" className="border-0">
                          Recovered
                        </th>
                        <th scope="col" className="border-0">
                          Deaths
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                        {this.countries_stats()}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
    );
  }

  fetchStats(){
      fetch("http://3.11.199.201/api.php?m=stats",{
          crossDomain:true
      })
         .then(response => response.json())
         .then(data => {
             this.setState({
                 smallStats : data
             });
         });
      // this.setState({
      //     smallStats : smallStats
      // });

  }

  fetchCountriesStats(){
      fetch("http://3.11.199.201/api.php?m=countries_stats",{
          crossDomain:true
      })
         .then(response => response.json())
         .then(data => {
             this.setState({
                 countries_stats : data
             });
         });
      // this.setState({
      //     countries_stats : countries_stats
      // });
  }

  componentDidMount(){
      // initializeFirebase();
      // askForPermissioToReceiveNotifications();
      //
      this.fetchStats();
      this.fetchCountriesStats();
  }
}

export default BlogOverview;
