import React, { useState } from "react";
import ReactPlayer from 'react-player'

import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import Offers from "./Offers.js";

import { initializeFirebase, askForPermissioToReceiveNotifications } from '../push-notification';

class Video extends React.Component {
  constructor(props) {
    super(props);
    //
    let { site_id,video_id } = this.props.match.params
    site_id = site_id && site_id!="" ? site_id : (this.props.site_id && this.props.site_id!="" ? this.props.site_id : 1);
    video_id = video_id && video_id!="" ? video_id : (this.props.video_id && this.props.video_id!="" ? this.props.video_id : "OZcRD9fV7jo");
    Video.defaultProps = {
        site_id: site_id,
        video_id: video_id
    };
    //
    this.state = {
        site_id: site_id,
        video_id: video_id,
        video_url: ''
    };
  }

  fetchVideos(){
      fetch("http://3.11.199.201/api.php?m=videos",{
          crossDomain:true
      })
     .then(response => response.json())
     .then(data => {
         this.setState({
             videos : data
         });
     });

  }

  getVideoUrl(){
        let url = "";
        const {site_id, video_id, video_url} = this.state;
        if(video_url!=""){
            return video_url;
        }
        if(site_id==1){
            url = 'https://www.youtube.com/watch?v='+video_id;
        }
         else if(site_id==2){
             let parts = video_id.split("-");
             url = 'https://www.facebook.com/'+parts[0]+'/videos/'+parts[1]+'/';
         }
         console.log(url);
         return url;
  }

  playVideo(video_url){
      this.setState({
         video_url: video_url,
         video_id : ""
      });
      window.scrollTo(0, 0);
  }

  listVideos(){
      let html = [];
      const {videos} = this.state;
      for(let i in videos){
          html.push(<Col md="4">
                        <a href="javascript:{}" onClick={() => this.playVideo(videos[i]["link"])}>
                            <img width="100%" height="120px" src={videos[i]["thumb"]}/>
                            {videos[i]["title"]}
                        </a>
                    </Col>);
      }
      return html;
  }

  render() {
      console.log(this.state.video_url);
    return (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
              <PageTitle title="Corona Virus Tracker" subtitle="Videos" className="text-sm-left mb-3" />
          </Row>
          <Offers/>
          <Row style={{"marginTop":"5px"}}>
            <Col md="12">
              <div style={this.state.video_url.indexOf("102678344710316") > -1
                            || this.state.video_id.indexOf("102678344710316") > -1 ? {"height":"100%"} : {"height":"320px"}}>
                    <ReactPlayer
                        url={this.getVideoUrl()}
                        width='100%'
                        height='100%'
                        />
              </div>
            </Col>
          </Row>
          <hr/>
          <Row>
              {this.listVideos()}
          </Row>
        </Container>
    );
  }

  componentDidMount(){
      // initializeFirebase();
      // askForPermissioToReceiveNotifications();
      this.fetchVideos();
  }
}

Video.defaultProps = {
    site_id: 1,
    video_id: "OZcRD9fV7jo"
};

export default Video;
