import React, { useState } from "react";
import ReactPlayer from 'react-player'

import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import Offers from "./Offers.js";

import { initializeFirebase, askForPermissioToReceiveNotifications } from '../push-notification';

class News extends React.Component {
  constructor(props) {
    super(props);
    //
    let { news_id } = this.props.match.params
    news_id = news_id && news_id!="" ? news_id : (this.props.news_id && this.props.news_id!="" ? this.props.news_id : "1");
    News.defaultProps = {
        news_id: news_id
    };
    //
    this.state = {
        news_id: news_id,
        news_url: 'https://www.dailymail.co.uk/news/article-8140609/Italian-grandmother-95-recovers-coronavirus.html'
    };
  }

  fetchNews(){
      fetch("http://3.11.199.201/api.php?m=news2",{
          crossDomain:true
      })
     .then(response => response.json())
     .then(data => {
         this.setState({
             news : data,
         });
     });

  }

  playNews(news_id){
      this.setState({
         news_url: this.state.news[news_id],
         news_id : news_id
      });
      window.scrollTo(0, 0);
  }

  listNews(){
      let html = [];
      const {news} = this.state;
      for(let i in news){
          html.push(<Col md="4">
                        <a href="javascript:{}" onClick={() => this.playNews(news[i]["link"])}>
                            <img width="100%" height="120px" src={news[i]["thumb"]}/>
                            {news[i]["headline"]}
                        </a>
                    </Col>);
      }
      return html;
  }

  render() {
    return (
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
              <PageTitle title="Corona Virus Tracker" subtitle="News" className="text-sm-left mb-3" />
          </Row>
          <Offers/>
          <Row style={{"marginTop":"5px"}}>
            <Col md="12">
              <div style={{"height":"10000px","width":"100%"}}>
                    <iframe
                        src={this.state.news ? this.state.news[this.state.news_id]["link"] : this.state.news_url}
                        width='100%'
                        height='100%'
                        frameborder='0'
                        />
              </div>
            </Col>
          </Row>
          <hr/>
          <Row>
              { /*this.listNews()*/ }
          </Row>
        </Container>
    );
  }

  componentDidMount(){
      // initializeFirebase();
      // askForPermissioToReceiveNotifications();
      this.fetchNews();
  }
}

News.defaultProps = {
    news_id: "1"
};

export default News;
