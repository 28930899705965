import React, { memo } from "react";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps";

// import map from "../../data/map.json";

const geoUrl = "http://3.11.199.201/api.php?m=map";
  // "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const rounded = num => {
  if (num > 1000000000) {
    return Math.round(num / 100000000) / 10 + "Bn";
  } else if (num > 1000000) {
    return Math.round(num / 100000) / 10 + "M";
  } else {
    return Math.round(num / 100) / 10 + "K";
  }
};

const MapChart = ({ setTooltipContent }) => {
  return (
    <>
      <ComposableMap data-tip="" projectionConfig={{ scale: 200 }}>
        <ZoomableGroup>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => {
                  const { NAME, corona } = geo.properties;
                  const fill = corona ? 'red' : '#D6D6DA';
                  const hover = corona ? 'yellow' : '#D6D6DA';
                  const pressed = corona ? 'red' : '#D6D6DA';
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() => {
                        if(corona){
                            setTooltipContent(`${NAME} (${corona})`);
                        }
                      }}
                      onMouseLeave={() => {
                        setTooltipContent("");
                      }}
                      style={{
                        default: {
                          fill: fill,
                          outline: "none"
                        },
                        hover: {
                          fill: hover,
                          outline: "none"
                        },
                        pressed: {
                          fill: pressed,
                          outline: "none"
                        }
                      }}
                    />
                )})
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </>
  );
};

export default memo(MapChart);
