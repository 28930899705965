import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import Video from "./views/Video";
import News from "./views/News";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";

export default [
  {
    path: "/",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/video/:site_id?/:video_id?",
    layout: DefaultLayout,
    component: Video
  },
  {
    path: "/news/:news_id?",
    layout: DefaultLayout,
    component: News
  }
];
